export const invoiceRefs = {
  computed: {
    invoiceRefIsRequired () {
      return this.documentCategory === 'creditNote' || this.documentCategory === 'debitNote'
    }
  }
}

export const invoiceLineTemplate = {
  data () {
    return {
      lineTemplate: {
        itemDescription: null,
        unitPrice: null,
        quantity: null,
        unitOfMeasure: null,
        vat: {
          percent: null,
          taxableAmount: null
        },
        comment: null,
        lineNetAmount: null
      }
    }
  }
}

export const orderLineTemplate = {
  data () {
    return {
      lineTemplate: {
        itemDescription: null,
        unitPrice: null,
        quantity: null,
        unitOfMeasure: null,
        vat: {
          percent: null,
          taxableAmount: null
        },
        comment: null,
        lineNetAmount: null
      }
    }
  }
}

export const references = {
  data () {
    return {
      dateProps: {
        generic: {},
        document: {
          menu: false,
          label: 'documents.create.docDate',
          icon: '',
          required: true
        },
        dueDate: {
          menu: false,
          label: 'documents.create.paymentDueDate',
          icon: '',
          required: true
        },
        purchaseOrder: {
          menu: false,
          label: 'documents.create.refs.orderDate',
          icon: '',
          required: false
        },
        invoice: {
          menu: false,
          label: 'documents.create.refs.invoiceDate',
          icon: '',
          required: true
        },
        proformInvoice: {
          menu: false,
          label: 'documents.create.refs.proformDate',
          icon: '',
          required: false
        },
        contract: {
          menu: false,
          label: 'documents.create.refs.contractDate',
          icon: '',
          required: false
        }
      }
    }
  }
}

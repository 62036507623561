<template>
  <p class="text-h6 font-weight-regular">
    {{ $t('general.contacts') }}
  </p>
</template>
<script>
export default {
  name: 'PartnersHeadline'
}
</script>

export const currencyList = {
  data () {
    return {
      currencies: [
        'EUR',
        'BGN',
        'DKK',
        'CZK',
        'HUF',
        'CHF',
        'NOK',
        'PLN',
        'RON',
        'SEK',
        'GBP'
      ]
    }
  }
}

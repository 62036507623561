<template>
  <v-card
    v-if="card"
    outlined
  >
    <v-card-text class="text--primary">
      <p class="subheading text--darken-4 uppercase">
        {{ card.name }}
      </p>
      <div>
        <span v-if="card.address">{{ card.address }} </span>
        <span v-if="card.city">{{ card.city }} </span>
        <span v-if="card.postalCode">{{ card.postalCode }} </span>
        <span v-if="card.countryName">{{ card.countryName }}</span>
      </div>
      <template v-for="item of getAllIdentifiers">
        <h1
          v-if="card[item]"
          :key="item"
          class="text-body-1"
        >
          <span class="text-body-2">{{ $t('supportedIdentifiers.' + item) }}: </span>{{ card[item] }}
        </h1>
      </template>
      <div
        v-if="card.personInCharge"
        class="text--darken-4 uppercase"
      >
        <span class="text-body-2">{{ $t('documents.create.properties.personInCharge') }}: </span>{{ card.personInCharge }}
      </div>
      <div v-if="card.phone">
        {{ card.phone }}
      </div>
      <div v-if="card.email">
        {{ card.email }}
      </div>
      <div v-if="card.website">
        {{ card.website }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PartnerCard',
  props: {
    card: { type: Object, default: () => { return {} } }
  },
  computed: {
    ...mapGetters([
      'getAllIdentifiers'
    ])
  }
}
</script>

export const supplier = {
  data () {
    return {
      supplier: {}
    }
  }
}

export const buyer = {
  data () {
    return {
      buyer: {}
    }
  }
}

export const deliverTo = {
  data () {
    return {
      deliverTo: {}
    }
  }
}

export const orderedBy = {
  data () {
    return {
      orderedBy: {}
    }
  }
}
export const supplierBankAccount = {
  data () {
    return {
      supplierBank: {}
    }
  }
}

export const buyerBankAccount = {
  data () {
    return {
      buyerBank: {}
    }
  }
}

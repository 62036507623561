<template>
  <v-row>
    <v-col cols="12">
      <custom-textarea
        v-model="item.itemDescription"
        auto-grow
        rows="1"
        :label="$t('documents.create.lines.itemDescription') + ' *'"
        :placeholder="$t('documents.create.commentLabel')"
        :rules="[rules.alwaysRequired]"
        counter="256"
        maxlength="256"
        data-cy="line-description"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="2"
    >
      <custom-text-field
        v-model="item.unitOfMeasure"
        :label="$t('documents.create.lines.unitOfMeasure') + ' *'"
        :placeholder="$t('general.code')"
        :rules="[rules.alwaysRequired]"
        counter="64"
        maxlength="64"
        data-cy="line-uom"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="3"
    >
      <custom-text-field
        v-model.number="item.unitPrice"
        v-numbers-only
        :label="$t('documents.create.lines.unitPrice') + ' *'"
        :placeholder="$t('general.numeric')"
        :rules="[rules.alwaysRequired, rules.numeric]"
        data-cy="line-unit-price"
        @input="updateLineTotalNet(item)"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="3"
    >
      <custom-text-field
        v-model.number="item.quantity"
        v-numbers-only
        :label="$t('documents.create.lines.quantity') + ' *'"
        :placeholder="$t('general.numeric')"
        :rules="[rules.alwaysRequired, rules.numeric]"
        data-cy="line-quantity"
        @input="updateLineTotalNet(item)"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="1"
    >
      <custom-text-field
        v-model.number="item.vat.percent"
        v-numbers-only
        placeholder="0.00"
        data-cy="line-percent"
        :label="$t('documents.create.lines.vat') + ' *'"
        :rules="[rules.alwaysRequired, rules.percent]"
      />
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="3"
    >
      <custom-text-field
        :value="$getNumber(item.lineNetAmount) | twoDecimals"
        readonly
        :hint="$t('general.calculated')"
        persistent-hint
        data-cy="line-net-amount"
        :label="$t('documents.create.lines.lineNetAmount')"
      />
    </v-col>
    <v-col cols="12">
      <custom-textarea
        v-model="item.comment"
        auto-grow
        rows="1"
        :label="$t('documents.create.lines.comment')"
        :placeholder="$t('documents.create.commentLabel')"
        data-cy="line-comment"
      />
    </v-col>
  </v-row>
</template>

<script>
import { commonLineInputMixins } from '@/mixins/create/lines/common'
import { updateLineTotalNet } from '@/mixins/create/lines/total_net'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomTextarea from '@/components/inputs/CustomTextarea'

export default {
  name: 'LinesInputs',
  components: {
    CustomTextField,
    CustomTextarea
  },
  mixins: [
    commonLineInputMixins,
    updateLineTotalNet
  ]
}
</script>

<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.lines.title') }}
    </p>
    <v-data-iterator
      :items="localLineItems"
      no-data-text=""
      hide-default-footer
      disable-pagination
      class="row"
    >
      <template v-slot:item="props">
        <v-col cols="12">
          <remove-line-item-button
            :index="getLineItemIndex(props.item)"
            :disabled="localLineItems.length < 2"
            @action:remove-line="removeLine(getLineItemIndex(props.item))"
          />
          <slot
            name="inputs"
            :item="props.item"
          />
        </v-col>
      </template>
      <template v-slot:footer>
        <add-line-item-button
          @action:add-line="addLine"
        />
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import RemoveLineItemButton from '@/components/create/inputs/lines/RemoveLineItemButton'
import AddLineItemButton from '@/components/create/inputs/lines/AddLineItemButton'

export default {
  name: 'LinesGroup',
  components: {
    RemoveLineItemButton,
    AddLineItemButton
  },
  props: {
    items: { type: Array, default: () => { return [] } },
    lineTemplate: { type: Object, default: () => { return {} } }
  },
  computed: {
    localLineItems () {
      return this.items
    }
  },
  mounted () {
    this.addLine()
  },
  methods: {
    getLineItemIndex (item) {
      return this.localLineItems.indexOf(item)
    },
    addLine () {
      this.localLineItems.push(JSON.parse(JSON.stringify(this.lineTemplate)))
    },
    removeLine (index) {
      this.localLineItems.splice(index, 1)
    }
  }
}
</script>

<template>
  <v-col cols="12">
    <v-row justify="end">
      <v-btn
        outlined
        rounded
        color="primary"
        class="mr-3"
        @click.native="$emit('action:add-line')"
      >
        <v-icon left>
          {{ $vuetify.icons.values.add }}
        </v-icon>
        {{ $t('documents.create.lines.add') }}
      </v-btn>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'AddLineItemButton'
}
</script>

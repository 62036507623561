<template>
  <v-col
    cols="12"
    sm="6"
    md="3"
  >
    <custom-autocomplete
      v-model="localPartner"
      :items="items"
      item-text="acronym"
      return-object
      :label="label"
      :rules="rules"
      :clearable="clearable"
      data-cy="document-partner"
    >
      <template v-slot:append-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-btn
              outlined
              color="primary"
              @click="$bus.emit('open:partner-create-dialog')"
            >
              <v-icon left>
                {{ $vuetify.icons.values.add }}
              </v-icon>
              {{ $t('general.add') }}
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </template>
    </custom-autocomplete>
    <partner-card
      v-if="localPartner.card"
      :card="localPartner.card"
    />
  </v-col>
</template>

<script>
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import PartnerCard from '@/components/create/cards/PartnerCard'
export default {
  name: 'PartnerAutocomplete',
  components: {
    CustomAutocomplete,
    PartnerCard
  },
  props: {
    items: { type: Array, default: () => { return [] } },
    partnerData: { type: Object, default: () => { return {} } },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => { return [] } },
    clearable: { type: Boolean, default: true }
  },
  computed: {
    localPartner: {
      get () {
        return this.partnerData
      },
      set (value) {
        this.$emit('update:partner-data', value || {})
      }
    }
  }
}
</script>

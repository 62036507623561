import { apiHttpService, externalHttpService } from '@/api'
import getNested from 'get-nested'
import router from '@/router'
import { getWorkspaceId, getViewContext, getRouteDetailId } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'
import { objectHasValues } from '@/api/utils/object_has_values'
import {
  getISOStringDateTimeGte,
  getISOStringDateTimeLte
} from '@/api/utils/iso_date'

const categoryMap = {
  invoice: 'invoice',
  credit_note: 'creditNote',
  debit_note: 'debitNote',
  proform_invoice: 'proformInvoice',
  purchase_order: 'purchaseOrder'
}

const categoryGroupsMap = {
  invoice: 'invoices',
  credit_note: 'invoices',
  debit_note: 'invoices',
  proform_invoice: 'invoices',
  purchase_order: 'purchaseOrders'
}

const mapResponse = response => {
  const mappedResponse = {
    id: response.id,
    docNum: response.number,
    docType: categoryMap[response.category],
    docDate: response.date,
    status: response.status,
    createdAt: response.created_at,
    completedAt: response.completed_at,
    sourceFiles: response.received_file_id,
    integrationFiles: response.integration_file,
    acquisitionPipeline: getNested(() => response.acquisition_pipeline.name, ' '),
    integrationPipeline: getNested(() => response.integration_pipeline.name, ' '),
    url: response.document
  }
  if (response.document_fields) {
    Object.assign(mappedResponse, response.document_fields)
  }
  return mappedResponse
}

const processResponseList = list => list.map(item => {
  return mapResponse(item)
})

export default {
  async getDocumentsList (queryParams) {
    let params = {}

    if (queryParams.activeFilters) {
      params = {
        number: queryParams.activeFilters.docNum,
        categories: queryParams.activeFilters.docType,
        status: queryParams.activeFilters.status,
        acquisition_pipeline_ids: queryParams.activeFilters.acquisitionPipeline,
        integration_pipeline_ids: queryParams.activeFilters.integrationPipeline,
        date_gte: queryParams.activeFilters.docDateFrom,
        date_lte: queryParams.activeFilters.docDateTo,
        created_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.createdAtFrom),
        created_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.createdAtTo),
        completed_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.completedAtFrom),
        completed_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.completedAtTo),
        received_file: queryParams.activeFilters.sourceFiles
      }
    }

    const documentFields = {
      supplier_name: queryParams.activeFilters.supplier_name,
      supplier_vat_id: queryParams.activeFilters.supplier_vat_id,
      supplier_reference: queryParams.activeFilters.supplier_reference,
      supplier_contact_name: queryParams.activeFilters.supplier_contact_name,
      buyer_name: queryParams.activeFilters.buyer_name,
      buyer_vat_id: queryParams.activeFilters.buyer_vat_id,
      buyer_reference: queryParams.activeFilters.buyer_reference,
      buyer_contact_name: queryParams.activeFilters.buyer_contact_name,
      po_reference: queryParams.activeFilters.po_reference,
      invoicing_reference: queryParams.activeFilters.invoicing_reference
    }

    if (objectHasValues(documentFields)) {
      params.document_fields = documentFields
    }

    setPaginationParams(queryParams, params, 'documents')

    params.scope = getViewContext()

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/',
        method: 'GET',
        params: arraySerializer(params),
        transformResponse: [data => {
          return {
            itemCount: data.count,
            pageCount: data.total_pages,
            items: processResponseList(data.results)
          }
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDocumentDetail () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId(),
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDocumentDetailById (id) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + id,
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getDocumentContent (url) {
    try {
      const response = await externalHttpService({
        url: url,
        method: 'GET'

      })
      if (response) return Promise.resolve(response.data)
      return Promise.resolve({ document: {} })
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async createDocument (form) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/',
        method: 'POST',
        data: form
      })
      if (response.status >= 200 && response.status < 300) {
        router.push({
          name: 'DocumentsSentDetails',
          params: {
            workspaceId: getWorkspaceId(),
            documentCategory: categoryGroupsMap[response.data.category],
            routeDetailId: response.data.id
          }
        })
      }
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async deleteDocument () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/documents/' + getRouteDetailId(),
        method: 'DELETE'
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

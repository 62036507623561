<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.totals.title') }}
    </p>
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <custom-text-field
          readonly
          :value="taxable | twoDecimals"
          :hint="$t('general.calculated')"
          persistent-hint
          :label="$t('documents.create.totals.totalNet')"
          data-cy="total-net"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <custom-text-field
          readonly
          :value="tax | twoDecimals"
          :hint="$t('general.calculated')"
          persistent-hint
          :label="$t('documents.create.totals.totalVat')"
          data-cy="total-vat"
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <custom-text-field
          readonly
          background-color="blue lighten-5"
          :value="total | twoDecimals"
          :hint="$t('general.calculated')"
          persistent-hint
          :label="$t('documents.create.totals.totalDue')"
          data-cy="total-due"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'Totals',
  components: {
    CustomTextField
  },
  props: {
    lineItems: { type: Array, default: () => { return [] } }
  },
  computed: {
    taxable () {
      const taxable = this.lineItems.reduce((sum, current) => {
        return sum + this.$getNumber(current.quantity * current.unitPrice)
      }, 0)
      const rounded = this.$roundTo(taxable, 2)
      this.$emit('update:total-net-amount', rounded)
      return rounded
    },
    tax () {
      const tax = this.lineItems.reduce((sum, current) => {
        return sum + this.$getNumber(current.quantity * current.unitPrice * (current.vat.percent / 100))
      }, 0)
      const rounded = this.$roundTo(tax, 2)
      this.$emit('update:total-vat-amount', rounded)
      return rounded
    },
    total () {
      const taxable = this.$getNumber(this.taxable)
      const tax = this.$getNumber(this.tax)
      const rounded = this.$roundTo(taxable + tax, 2)
      this.$emit('update:total-gross-amount', rounded)
      this.$emit('update:payment-remaining', rounded)
      return rounded
    }
  }
}
</script>

<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.refs.generic') }}
    </p>
    <v-data-iterator
      :items="localReferences"
      no-data-text=""
      hide-default-footer
      disable-pagination
      class="row"
    >
      <template v-slot:item="props">
        <v-col
          cols="12"
          data-cy="document-references-row"
        >
          <v-row justify="space-between">
            <v-avatar>
              <span class="text-h5">
                {{ getGenericRefIndex(props.item) + 1 }}
              </span>
            </v-avatar>
            <v-btn
              icon
              class="mr-3"
              data-cy="references-remove"
              @click.native="removeGenericRef(getGenericRefIndex(props.item))"
            >
              <v-icon>
                {{ $vuetify.icons.values.delete }}
              </v-icon>
            </v-btn>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <custom-autocomplete
                v-model="props.item.scope"
                :items="genericRefs"
                clearable
                :label="$t('documents.create.refs.scope') + ' *'"
                :placeholder="$t('general.select')"
                :rules="[rules.alwaysRequired]"
                data-cy="references-scope"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="5"
            >
              <custom-textarea
                v-model="props.item.identifier"
                auto-grow
                rows="1"
                :label="$t('documents.create.refs.identifier') + ' *'"
                :placeholder="$t('documents.create.commentLabel')"
                :rules="[rules.alwaysRequired]"
                counter="128"
                maxlength="128"
                data-cy="references-identifier"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <v-menu
                :ref="'refDate' + getGenericRefIndex(props.item)"
                v-model="props.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :return-value.sync="props.item.date"
                style="display:inherit;"
              >
                <template v-slot:activator="{ on }">
                  <custom-text-field
                    v-model="props.item.date"
                    outlined
                    :prepend-icon="$vuetify.icons.values.date"
                    clearable
                    readonly
                    :label="$t('general.date')"
                    :placeholder="$t('general.select')"
                    data-cy="references-date"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="props.item.date"
                  first-day-of-week="1"
                  scrollable
                  :landscape="$vuetify.breakpoint.mdAndUp"
                  :locale="$i18n.locale"
                >
                  <v-spacer />
                  <v-btn
                    text
                    rounded
                    color="primary"
                    @click="props.menu = false"
                  >
                    {{ $t('general.close') }}
                  </v-btn>
                  <v-btn
                    text
                    rounded
                    color="primary"
                    data-cy="date-save"
                    @click="$refs['refDate' + getGenericRefIndex(props.item)].save(props.item.date)"
                  >
                    {{ $t('general.save') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <custom-textarea
                v-model="props.item.text"
                auto-grow
                rows="1"
                :rules="[rules.alwaysRequired]"
                :label="$t('documents.create.comment') + ' *'"
                :placeholder="$t('documents.create.commentLabel')"
                counter="128"
                maxlength="128"
                data-cy="references-comment"
              />
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot:footer>
        <v-col cols="12">
          <v-row justify="end">
            <v-btn
              outlined
              rounded
              color="primary"
              class="mr-3"
              data-cy="add-references-button"
              @click.native="addGenericRef"
            >
              <v-icon left>
                {{ $vuetify.icons.values.add }}
              </v-icon>
              {{ $t('documents.create.refs.add') }}
            </v-btn>
          </v-row>
        </v-col>
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomTextarea from '@/components/inputs/CustomTextarea'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export default {
  name: 'ReferencesGroup',
  components: {
    CustomTextField,
    CustomTextarea,
    CustomAutocomplete
  },
  props: {
    items: { type: Array, default: () => { return [] } },
    rules: { type: Object, default: () => { return {} } }
  },
  computed: {
    localReferences: {
      get () {
        return this.items
      },
      set (value) {
        this.$emit('update:items', value)
      }
    },
    genericRefs () {
      return [
        { value: 'general', text: this.$t('documents.create.refs.general') },
        { value: 'contract', text: this.$t('documents.create.refs.contract') },
        { value: 'purchase_order', text: this.$t('documents.create.refs.purchaseOrder') },
        { value: 'credit_note', text: this.$t('documents.create.refs.creditNote') },
        { value: 'debit_note', text: this.$t('documents.create.refs.debitNote') },
        { value: 'invoice', text: this.$t('documents.create.refs.genericInvoice') },
        { value: 'proform_invoice', text: this.$t('documents.create.refs.proformInvoice') },
        { value: 'agreement', text: this.$t('documents.create.refs.agreement') },
        { value: 'delivery', text: this.$t('documents.create.refs.delivery') },
        { value: 'shipment', text: this.$t('documents.create.refs.shipment') },
        { value: 'acccounting', text: this.$t('documents.create.refs.acccounting') },
        { value: 'additional_conditions', text: this.$t('documents.create.refs.additionalConditions') },
        { value: 'reason_for_request', text: this.$t('documents.create.refs.reasonForRequest') },
        { value: 'quality_statement', text: this.$t('documents.create.refs.qualityStatement') },
        { value: 'statement', text: this.$t('documents.create.refs.statement') },
        { value: 'scope', text: this.$t('documents.create.refs.scope') },
        { value: 'review', text: this.$t('documents.create.refs.review') },
        { value: 'copyright', text: this.$t('documents.create.refs.copyright') },
        { value: 'location', text: this.$t('documents.create.refs.location') },
        { value: 'sales', text: this.$t('documents.create.refs.sales') }
      ]
    }
  },
  methods: {
    getGenericRefIndex (item) {
      return this.localReferences.indexOf(item)
    },
    addGenericRef () {
      this.localReferences.push({
        scope: null,
        identifier: null,
        text: null,
        date: null
      })
    },
    removeGenericRef (index) {
      this.localReferences.splice(index, 1)
    }
  }
}
</script>

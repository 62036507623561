export const updateLineTotalNet = {
  methods: {
    updateLineTotalNet (item) {
      const unitPrice = this.$getNumber(item.unitPrice)
      const quantity = this.$getNumber(item.quantity)
      item.lineNetAmount = this.$roundTo(unitPrice * quantity, 2)
      item.vat.taxableAmount = this.$roundTo(unitPrice * quantity, 2)
    }
  }
}

export const commonCreateProps = {
  props: {
    documentCategory: { type: String, default: '' },
    pipeline: { type: Number, default: null },
    language: { type: String, default: 'en' },
    isPushEmail: { type: Boolean, default: false },
    recipients: { type: Array, default: () => { return [] } },
    recipientsError: { type: Boolean, default: false },
    rules: { type: Object, default: () => { return {} } },
    activeWorkspacePipelines: { type: Array, default: () => { return [] } },
    myCompanies: { type: Array, default: () => { return [] } },
    clientCompanies: { type: Array, default: () => { return [] } },
    myBankAccounts: { type: Array, default: () => { return [] } },
    source: { type: Object, default: () => { return {} } },
    isCopyOrFlip: { type: Boolean, default: false }
  },
  data () {
    return {
      form: {
        valid: false
      },
      submitDisabled: false,
      submitColor: 'primary'
    }
  },
  mounted () {
    if (this.isCopyOrFlip) {
      const source = JSON.parse(JSON.stringify(this.source))
      Object.assign(this.document, source.document)
      this.document.documentCategory = this.documentCategory
    }
  },
  wathch: {
    'form.valid' (value) {
      if (value) this.submitColor = 'primary'
    }
  }
}

<template>
  <v-btn
    :disabled="submitDisabled"
    :color="submitColor"
    fixed
    type="submit"
    bottom
    right
    rounded
    data-cy="button-submit"
  >
    <v-icon left>
      {{ $vuetify.icons.values.send }}
    </v-icon>
    {{ $t('documents.create.submit') }}
  </v-btn>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    submitDisabled: { type: Boolean, default: true },
    submitColor: { type: String, default: '' }
  }
}
</script>

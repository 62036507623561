<template>
  <img
    v-if="logoUrl"
    :src="logoUrl"
    class="mx-auto d-flex"
    width="100%"
    height="auto"
  >
  <div v-else>
    <alert
      type="info"
      :text="$t('documents.create.noLogoMessage')"
    />
    <div
      v-if="$route.name !== 'DocumentsInboxDetails'"
      class="d-flex justify-space-around py-6"
    >
      <v-btn
        outlined
        rounded
        :to="{ name: 'ClientDetails', params: {
          clientId: activeWorkspace.clientId
        }}"
      >
        {{ $t('sidebar.administration.dashboard') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/alerts/Alert'

export default {
  name: 'ClientLogo',
  components: {
    Alert
  },
  props: {
    documentDetail: { type: Object, default: () => { return {} } }
  },
  computed: {
    ...mapGetters([
      'activeWorkspace'
    ]),
    logoUrl () {
      return this.$route.name === 'DocumentsInboxDetails'
        ? this.documentDetail.sender_logo
        : this.documentDetail.sender_logo || this.activeWorkspace.logo
    }
  }
}
</script>

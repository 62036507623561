import { mapState, mapGetters } from 'vuex'

export const setPartner = {
  computed: {
    ...mapState([
      'supportedPartnerTypes'
    ]),
    ...mapGetters([
      'allWorkspacePartnersSnippets'
    ])
  },
  methods: {
    setPartner (role, data) {
      this[role] = JSON.parse(JSON.stringify(data))
    },
    populatePartners () {
      this.supportedPartnerTypes.map(partnerCategory => {
        if (this.source[partnerCategory]) {
          const company = this.allWorkspacePartnersSnippets.find(
            partner => partner.card.name === this.source[partnerCategory].name
          )
          if (company) {
            this.setPartner(partnerCategory, company)
          }
        }
      })
    }
  },
  watch: {
    allWorkspacePartnersSnippets: {
      immediate: true,
      handler: function () {
        if (this.isCopyOrFlip) this.populatePartners()
      }
    }
  }
}

<template>
  <v-row justify="space-between">
    <v-avatar>
      <span class="text-h5">
        {{ index + 1 }}
      </span>
    </v-avatar>
    <v-btn
      icon
      :disabled="disabled"
      class="mr-3"
      @click.native="$emit('action:remove-line')"
    >
      <v-icon>
        {{ $vuetify.icons.values.delete }}
      </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: 'RemoveLineItemButton',
  props: {
    index: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false }
  }
}
</script>
